.profile-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100vh;
    background-color: white;
    color: black;
    position: relative;
    top: 200px;
    margin: 50px 0px 250px 0px;
    z-index: 2;
}

.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-intro {
    margin: 6px;
    font-size: 40px;
    font-weight: 900px;
}

.profile-intro-small {
    font-size: 26px;
}

.profile-details-header {
    font-size: 26px;
    font-weight: 500px;
    margin: 40px 0px 20px 0px;
}

.profile-details {
    margin: 0px;
    font-size: 20px;
}

.profile-image {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.edit-profile-button {
    position: absolute; 
    top: 0px;
    right: 80px;
    padding: 10px 12px;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.edit-profile-button:hover {
    color: white;
    background-color: black;
}