.home {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    position: fixed; 
    top: 0px;
    bottom: 0px;
}

.info-unauthenticated {
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    position: fixed;
    bottom: 80px;
    left: 100px;
    z-index: 2;
}

.info-unauthenticated h1 {
    font-size: 80px;
    font-weight: 700;
    margin: 0px;
}

.info-unauthenticated p {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.register-button {
    border-width: 2px;
    border-color: white;
    border-radius: 4px;
    color: white;
    padding: 10px 15px;
    max-width: 130px;
    font-size: 24px;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.register-button:hover {
    background-color: white;
    color: black;
}

.register-button:active {
    opacity: 0.7;
}

.info-authenticated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    color: black;
    background-color: rgba(255, 251, 251, 0.5);
    padding: 50px;
    z-index: 2;
    border-radius: 30px;
}

.info-authenticated p {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    margin: 0px;
    margin-bottom: 10px;
}

/*applies to home.js navbar.js*/
.search-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 30px;
    max-width: 500px; 
    width: 100%; 
    flex-shrink: 1;
}

/*applies to home.js navbar.js*/
.search-bar {
    position: relative;
    width: 460px;
    height: 30px;
    font-size: 20px;
    border: none;
    outline: none;
    padding-left: 6px;
}

/*applies to home.js navbar.js*/
.search-icon {
    font-size: 20px;
}

/*applies to home.js navbar.js*/
.search-button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

@media screen and (max-width: 768px) {
    
    .info-unauthenticated {
        width: 300px;
        bottom: 50px;
        left: 50px;
    }

    .info-unauthenticated h1 {
        font-size: 50px;
    }
    
    .info-unauthenticated p {
        font-size: 26px;
    }

    .register-button {
        width: 100%;
        font-size: 20px;
    }
    
    .search-bar {
      width: 100%;
      font-size: 16px; 
    }
}

