.edit-form-page {
    display: flex;
    justify-content: center;
    color: black;
    background-color: white;
    position: relative;
    top: 200px;
    z-index: 2;
}

.edit-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    min-height: 100vh;
    width: 1000px;
    margin: 0px;
    padding: 20px 0px 80px 0px;
    border: 2px solid black;
    border-radius: 20px;
    margin: 50px;
}

.edit-form-label {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: 20px;
}

.edit-form-header {
    font-size: 50px;
    font-weight: 900;
    margin: 35px 0px 35px 0px;
}

.edit-form-inputs {
    margin-bottom: 20px;
    width: 300px;
    padding: 4px;
    font-size: 22px;
    border-radius: 6px;
}

.edit-form-text-area {
    width: 500px;
    height: 100px;
    max-height: 200px;
    min-height: 50px;
    resize: vertical;
}

.save-button {
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 22px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.save-button:hover, .modify-list-button:hover, .edit-form-back-button:hover {
    background-color: black;
    color: white;
}

.modify-list-button {
    padding: 4px 10px;
    font-size: 22px;
    margin-bottom: 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}
  
.create-group-status {
    color: red;
    font-size: 22px;
}

.edit-form-back-button {
    position: relative;
    font-size: 22px;
    margin-left: 30px;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.edit-form-header-container {
    display: flex;
    align-items: center;
}