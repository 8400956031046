.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: fixed; 
    top: 0px;
    bottom: 0px;
}

.register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 500px;
    height: 450px;
    padding: 40px;
    border: 2px solid white;
    border-radius: 20px;
    background-color: white;
}

.register-form h1 {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 0px;
    font-size: 40px;
    font-weight: 900;
}

.register-form input {
    display: block; 
    font-size: 16px;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 2px;
}

.register-form button {
    display: block;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    padding: 10px;
    background-color: rgb(220, 125, 24);
    color: white;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.register-form button:hover {
    background-color: rgb(205, 114, 17);
}

.register-form p {
    font-size: 20px;
    margin: 0px;
    text-align: center;
}

.register-form a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: color 0.2s, text-decoration 0.2s;
}

.register-form a:hover {
    color: rgb(220, 125, 24);
    text-decoration: underline;
}

.register-status { 
    color: red;
    text-align: center;
    font-size: 20px;
    margin: 0px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    
    .register-form {
        margin: 20px;
        margin-top: 100px;
        height: 400px;
    }

    .register-form h1 {
        font-size: 30px;
    }

    .register-form p {
        font-size: 18px;
    }

    .register-status {
        font-size: 16px;
    }
}