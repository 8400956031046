.individual-group-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: scroll;
    line-height: 100%;
    border: 1px solid black;
    height: calc(100vh - 330px);
}

.chat-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;
}

#creator {
    background-color: lightblue;
}

#other {
    background-color: white;
}

.message-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    border: 1px solid black;
    border-radius: 20px;
    margin: 3px;
    padding: 16px;
    font-family: Arial;
}

.message-creator {
    display: inline-block;
    font-weight: bold;
    margin: 0px;
}

.message-content {
    font-size: 15px;
    margin: 3px 0px 0px 0px;
}

.message-time {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    margin: 3px 0px 0px 0px;
}

.chat-inputs {
    position: fixed;
    bottom: 20px;
    z-index: 1;
}

.chat-inputs input {
    font-size: 16px;
    padding: 10px;
    width: 800px;
    border-radius: 20px;
}


