.group-details-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 100vh;
    background-color: white;
    color: black;
    position: relative;
    top: 200px;
    margin: 50px 0px 250px 0px;
    z-index: 2;
}

.group-details {
    font-size: 22px;
    width: 1000px;
    padding: 80px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    height: auto;
    min-height: 100vh;
}

.group-intro {
    display: flex;
    align-items: center;
    position: relative;
}

.group-intro .app-icon {
    position: absolute;
    right: 0px;
}

.join-group {
    font-size: 30px;
    padding: 10px 16px;
    margin-left: 30px;
    border-radius: 10px;
    color: rgb(220, 125, 24);
    border: 2px solid rgb(220, 125, 24);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.join-group:hover {
    background-color: rgb(220, 125, 24, 0.8);
    color: black;
    border: 2px solid black;
}

.group-functions {
    display: flex;
    flex-direction: column;
    margin: 20px;
    background-color: rgb(160, 157, 157, 0.7);
    border-radius: 20px;
    padding: 20px;
}

.group-name {
    font-size: 60px;
    margin: 0px;
}

.member-or-requested {
    font-size: 30px;
    padding: 10px 16px;
    margin-left: 30px;
    border-radius: 10px;
    cursor: pointer;
    color: gray;
    border: 1px solid gray;
    opacity: 0.7;
    cursor: auto;
}

.group-description {
    margin: 20px 0px 50px 0px;
    font-size: 40px;
}

.modules-container { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    margin: 0px 0px 50px 0px;
    background-color: rgb(160, 157, 157, 0.7);
    padding: 20px;
    border-radius: 20px;
    width: 960px;
    height: 200px;
}

.group-subheaders {
    margin: 0px 0px 20px 0px;
    font-size: 40px;
}

.group-modules {
    font-size: 30px;
    text-align: center;
}

.group-members {
    font-size: 24px;
    margin: 0px 0px 4px 0px;
}

/*
.edit-group-button {
    font-size: 30px;
    padding: 10px 16px;
    margin-left: 30px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.edit-group-button:hover {
    background-color: black;
    color: white;
}

.leave-button {
    font-size: 30px;
    padding: 10px 16px;
    margin-left: 30px;
    border-radius: 10px;
    color: rgb(180, 49, 49);
    border: 2px solid rgb(180, 49, 49);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.leave-button:hover {
    background-color: rgb(180, 49, 49, 0.7);
    color: black;
    border: 2px solid black;
}

.chat-button {
    font-size: 30px;
    padding: 10px 16px;
    margin-left: 30px;
    border-radius: 10px;
    color: black;
    border: 2px solid black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.chat-button:hover {
    background-color: black;
    color: white;
}
*/