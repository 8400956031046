.dropdown-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -66px;
    right: 84px;
    background-color: white;
    border-radius: 16px;
    border: 1px solid gray;
    width: 140px;
    height: 120px;
}

.dropdown-menu::before {
    content: '';
    position: absolute;
    bottom: 107px;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
    background: white;
    border: 1px solid gray;
    border-bottom-color: white;
    border-right-color: white;
}

#dropdown-option {
    color: black;
    margin: 0px;
    height: 100%;
    width: 100%;
}

#dropdown-option:hover {
    color: white
}
  
.tooltip-close {
    display: none;
}