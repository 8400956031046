.navbar {
  width: 100%;
  height: 200px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  z-index: 3;
} 

.navbar .left-side {
  flex: 1; 
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 100px;
}

.navbar .middle {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .right-side {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
}

/*applies to navbar.js accounticon.js groupchaticon.js*/
.navbar .link {
  color: white;
  text-decoration: none;
  margin: 30px;
  font-size: 30px;
  transition: color 0.3s;
}

/*applies to navbar.js accounticon.js groupchaticon.js*/
.navbar .link:hover {
  color:rgb(220, 125, 24);
}

/*applies to navbar.js accounticon.js groupchaticon.js*/
.navbar .link:active {
  opacity: 0.7;
}

.tooltip {
  font-size: 14px;
  position: absolute;
  background-color: gray;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  bottom: -50px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  pointer-events: none;
}

/*applies to navbar.js accounticon.js groupchaticon.js*/
.navbar .link:hover .tooltip {
  opacity: 1;
}

.login-button {
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.login-button:hover {
  background-color: white;
  color: black;
}

.login-button:active {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
    
  .navbar .right-side {
    padding-right: 50px;
  }

}