.search-results-page {
    display: flex;
    flex-wrap: wrap;
    color: black;
    background-color: white;
    position: relative;
    top: 200px;
    z-index: 2;
    width: 100%;
}

.no-results-page {
    display: flex;
    align-items: center;
    position: relative;
    top: 200px;
}

.no-result-details {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 300px;
    bottom: 300px;
    right: 300px;
    left: 300px;
}

.no-result-details h1 {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.no-result-details p {
    font-size: 30px;
    margin: 0px;
}

.inactive-button {
    position: relative;
    top: 30px;
    left: 30px;
    margin-bottom: 30px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 10px 16px;
    font-size: 20px;
    cursor: pointer;
}

.active-button {
    position: relative;
    top: 30px;
    left: 30px;
    margin-bottom: 30px;
    margin-right: 20px;
    background-color: rgb(220, 125, 24, 0.8);
    border-radius: 20px;
    padding: 10px 16px;
    font-size: 20px;
    color: black;
    border: 2px solid black;
    cursor: pointer;
}

.individual-result-container {
    flex-basis: 100%;
    margin: 30px 30px 10px 30px;
    padding: 50px;
    background-color: rgb(160, 157, 157, 0.8);
    width: 400px;
    height: 200px;
    border: 2px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.individual-result-container:hover {
    opacity: 0.8;
}