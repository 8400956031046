.group-page {
    display: flex;
    flex-wrap: wrap;
    color: black;
    background-color: white;
    position: relative;
    top: 200px;
    z-index: 2;
    width: 100%;
}

.no-groups-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 180px;
    text-align: center;
    height: calc(100vh - 200px);
}

.no-groups-page h1 {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.no-groups-page p {
    font-size: 30px;
    margin: 0px;
}

.create-group-container {
    position: fixed;
    right: 135px;
    top: 320px;
    z-index: 3;
    background-color: lightblue;
}

.create-group-button {
    position: fixed;
    right: 50px;
    top: 250px;
    font-size: 30px;
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 50%; 
    border: 3px solid black;
    cursor: pointer;
    transition: background-color 0.3s;
}

.create-group-tooltip {
    position: absolute;
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
    pointer-events: none;
    z-index: 4;
}
  
.create-group-container:hover .create-group-tooltip {
    opacity: 1;
}

.create-group-button:hover {
    background-color: rgb(220, 125, 24);
}

.individual-group-container {
    flex-basis: 100%;
    margin: 30px 30px 10px 30px;
    padding: 50px;
    background-color: rgb(160, 157, 157, 0.8);
    width: 400px;
    height: 200px;
    border: 2px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.individual-group-container:hover {
    opacity: 0.8;
}

.modules-description { 
    font-size: 20px;
}

